import {
  chunk, get, intersection,
} from 'lodash';
import { getStates } from '../store';
import {
  nodeMiddlewareClient,
  timeZoneOffset,
} from './base';
import { fromLocation } from '../api-transforms';

export const getLocations = async ({
  page,
  pageSize,
  searchString,
  status,
  allFilters = {},
  sortBy,
  sortDirection,
  fields = [],
}) => {
  const { global: { merchantIds }, auth: { locations: userAccessedLocations, countries } } = getStates();
  const explicitCountryFilter = get(allFilters, 'country', []);
  const countryAccess = explicitCountryFilter.length
    ? intersection(countries, explicitCountryFilter)
    : countries;
  const updatedFilters = {
    ...allFilters,
    location_id: userAccessedLocations,
    country: countryAccess,
  };
  const {
    data: {
      pagination: locationPagination = {},
      items = [],
    },
  } = await nodeMiddlewareClient.get('/locations', {
    params: {
      merchant: merchantIds,
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize } : {}),
      ...(status ? { status } : {}),
      ...(searchString ? { searchString } : {}),
      ...(sortBy ? { sortBy } : {}),
      ...((sortBy && sortDirection) ? { sortDirection: sortDirection.toUpperCase() } : {}),
      ...(fields.length ? { fields } : {}),
      ...updatedFilters,
      tzoffset: timeZoneOffset,
    },
  });
  return {
    totalLocations: get(locationPagination, 'total'),
    locations: items.map(fromLocation),
  };
};

export const getLocationDetails = async ({
  locationIds = [],
  locationNames = [],
  locationCodes = [],
}) => {
  // eslint-disable-next-line no-nested-ternary
  const locationsToFetch = locationIds.length
    ? locationIds
    : (locationNames.length ? locationNames : locationCodes);
  // eslint-disable-next-line no-nested-ternary
  const fieldProps = locationIds.length ? 'location_id' : (locationNames.length ? 'location_name' : 'location_code');

  const allRequestedLocations = await Promise.all(chunk(locationsToFetch, 20).map((identifiers) => nodeMiddlewareClient.get('/frontend/locations', {
    params: {
      [fieldProps]: identifiers,
      tzoffset: timeZoneOffset,
    },
  })));
  return {
    locations: allRequestedLocations.flatMap(({ data }) => get(data, 'items', [])).map(fromLocation),
  };
};
